const imagenes = [
    "../build/img/svg/ejemplos.svg",
    "../build/img/svg/ejemplos2.svg",
    "../build/img/svg/ejemplos3.svg"
    
]

const img = document.querySelector("#imagen");
let i = 0;

const slideShow = () => {
    img.src = imagenes[i]; 

    if(i < imagenes.length - 1) {
        i = i + 1;
    } else {
        i = 0;
    }
}  

const siguiente = () => {
    setInterval(slideShow, 2000);
}

empezarEvento();
function empezarEvento() {
    document.addEventListener("DOMContentLoaded", siguiente);
}
